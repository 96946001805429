import React, { useState } from "react";
import Lottie from "lottie-web-react";
import animationDataPopUp from "../../ANIMATIONS/subzeroSidebarANim/open.json";
import animationDataPopIn from "../../ANIMATIONS/subzeroSidebarANim/close.json";

const renderer = "svg";
const rendererSettings = {
  preserveAspectRatio: "xMinYMin slice",
};

function SubzeroSidebarAnimation() {
  const [playingState, setPlayingState] = useState("stop");
  const [popUp, setpopUp] = useState("svg");
  const [autoplay] = useState(true);
  const [loop] = useState(false);
  return (
    <div
      style={{
        width: "60px",
        height: "60px",
        display: "flex",
        flexDirection: "row",
      }}
      onMouseEnter={() => {
        setpopUp(true);
        setPlayingState("play");
      }}
      onClick={() => setPlayingState("stop")}
      onMouseLeave={() => {
        if (playingState === "play") {
          setpopUp(false);
        }
      }}
    >
      <Lottie
        // style={{  }}
        id="subzeroSidebarAnim"
        options={{
          renderer: renderer,
          loop: loop,
          autoplay: autoplay,
          animationData: popUp ? animationDataPopUp : animationDataPopIn,
          rendererSettings: rendererSettings,
        }}
        playingState={playingState}
      />
    </div>
  );
}
export default SubzeroSidebarAnimation;
