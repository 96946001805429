import React, { useState } from "react";
// import CardActionArea from '@material-ui/core/CardActionArea'
// import CardActions from '@material-ui/core/CardActions'
import CardMui from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import animbackground from "../../../ANIMATIONS/Accordion/Component=Accordion.svg";
import PageIllustrationAnimation from "../../loadingAnimation/PageIllustrationAnimation";
import "./pageIllustrationStyle.scss";

export default function PageIllustrations({
  animationData,
  desc,
  animPlay = true,
}) {
  const [isHovered, setisHovered] = useState(animPlay);
  return (
    <>
      <CardMui
        elevation={0}
        classes={{
          root: "pageIllustration",
        }}
        onMouseEnter={() => setisHovered(true)}
        // onMouseLeave={() => setisHovered(false)}
      >
        {/* <CardActionArea> */}
        <CardMedia
          // style={{ width: '576px', height: '228px' }}
          image={animbackground}
          component="div"
        >
          <div>
            {animationData ? (
              <PageIllustrationAnimation
                isHovered={isHovered}
                animationData={animationData}
                setisHovered={setisHovered}
              />
            ) : null}
          </div>
        </CardMedia>
        {/* </CardActionArea> */}
      </CardMui>
      <p className="compIllustrations__para paraContain">{desc}</p>
    </>
  );
}
