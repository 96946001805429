import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footerContainer">
      <div className="contenttitle">@Subzero Design System 2021</div>
      <div className="footerRightContainer">
        <a
          className="footerRightContainer__link"
          href=" https://www.axisbank.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="content right-gutter-mb">Axis Bank</div>
        </a>

        <a
          className="footerRightContainer__link"
          // href="https://docs.google.com/forms/d/e/1FAIpQLSfaklI8yFWMbIz4RwB0faCAxDrmfhdpZfTdE8hk4uYyuxUn9w/viewform?usp=sf_link"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="content right-gutter-mb">Accord</div>
        </a>
        <a
          className="footerRightContainer__link"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="content">Join us</div>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
