import React from "react";
import commingSoonImg from "../../commonComponents/assets/comp-illustrations.png";
import BackFrontTab from "../../commonComponents/BackFrontTab";

function ColorCode(props) {
  return (
    <div>
      <br />
      <br />
      <img src={commingSoonImg} alt="CommingSoonPLaceholderimg" />
      <br />
      <br />
      <p className="paraContain">
        This page is a work in progress right now. We are working hard in the
        meantime to get this up and running. We’ll let you know when this page
        is freshly baked.
      </p>
      <BackFrontTab
        previousTab="Colors: Style"
        nextTab="Spacing"
        linkNext="/spacing"
        setter={props.setter}
        index={props.index}
      />
    </div>
  );
}

export default ColorCode;
