import React from "react";
import "./colorPage.scss";

// import CompIllustrations from '../../commonComponents/compIllustrations/CompIllustrations'
// import colorsIllust from '../../../assets/illustrationComps/Component=color.png'
import animationData from "../../../ANIMATIONS/Color/Colors.json";
import animBackground from "../../../ANIMATIONS/Color/Color.svg";

import { TitleClipIcon } from "../../commonComponents/pageComponents";
import BackFrontTab from "../../commonComponents/BackFrontTab";
import PageIllustration from "../../commonComponents/pageIllustration/PageIllustrations";
import { Grid } from "@material-ui/core";

function ColorsOverview(props) {
  return (
    <div className="colorPage">
      {/* <CompIllustrations img={colorsIllust} desc='Colors bring visual harmony and coherence to the product’s experience.' /> */}

      <PageIllustration
        animationData={animationData}
        animBackground={animBackground}
        desc="Colors bring visual harmony and coherence to the product’s experience."
      />
      <PrimaryColors />
      <SecoundryPage />
      <TertiaryPage />
      <BackFrontTab
        previousTab="Typography"
        nextTab="Colors: Style"
        linkPrev="/typography"
        setter={props.setter}
        index={props.index}
      />
    </div>
  );
}

export default ColorsOverview;

const PrimaryColors = () => {
  return (
    <div className="primaryColors">
      <TitleClipIcon title="Primary colors" />
      <div className="paraContain">
        <p>
          The primary color palette is the strongest impression of the Axis
          brand. It leads to communications, being supported by the secondary
          and extended palettes.
        </p>
      </div>
      <div className="ColorSchemeContainer">
        <Grid spacing={1} container>
          <Grid spacing={1} item container justifyContent="space-between">
            <Grid item xl={3} lg={3} sm={6} xs={9}>
              <div className="div11">
                Burgundy-100
                <br />
                <div className="colorCode">#97144D</div>
              </div>
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div121" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div122" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div123" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div124" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div125" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div126" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div127" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div128" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="div129" />
            </Grid>
          </Grid>

          <Grid spacing={1} item container>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <div className="div21">
                White
                <br />
                <div className="colorCode">#FFFFFF</div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <div className="div22">
                Charcoal
                <br />
                <div className="colorCode">#282828</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// /////////////////////////////////////////////////////
// secoundry Page
// ///////////////////////////////////////////////////

const SecoundryPage = () => {
  return (
    <div className="secoundryColor">
      <TitleClipIcon title="Secondary colors" />
      <div className="paraContain">
        <p>
          The secondary color palette supports the primary colors via
          reinforcing the intended expression and help bring life and vibrancy
          to communications.
        </p>
      </div>
      <div className="ColorSchemeContainer">
        <Grid spacing={1} container>
          <Grid spacing={1} item container justifyContent="space-between">
            <Grid item xl={2} lg={2} sm={6} xs={12}>
              <div className="sdiv111">
                Pink-100
                <br />
                <div className="colorCode">#ED1164</div>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} sm={6} xs={6}>
              <div className="sdiv112">
                Pink-80
                <br />
                <div className="colorCode">#F146 87</div>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} sm={6} xs={6}>
              <div className="sdiv113">
                Pink-60
                <br />
                <div className="colorCode">#F57BA9</div>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} sm={6} xs={6}>
              <div className="sdiv114">
                Pink-40
                <br />
                <div className="colorCode">#F9B0CC</div>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} sm={6} xs={6}>
              <div className="sdiv115">
                Pink-20
                <br />
                <div className="colorCode">#FDE5EE</div>
              </div>
            </Grid>
          </Grid>

          <Grid spacing={1} item container justifyContent="space-between">
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <div className="sdiv21">
                Grey-100
                <br />
                <div className="colorCode">#404040</div>
              </div>
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv221" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv222" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv223" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv224" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv225" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv226" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv227" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv228" />
            </Grid>
            <Grid item xl={1} lg={1} sm={6} xs={3}>
              <div className="sdiv229" />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// ///////////////////////////////////////////////////////////////////////////////
// /////////////        TERITAIARY Color   //////////////////////////////////
// ////////////////////////////////////////////////////////////////////////////////

const TertiaryPage = () => {
  return (
    <div className="tertioryColor">
      <TitleClipIcon title="Tertiary colors" />
      <div className="paraContain">
        <p>
          The tertiary color palette acts as a form of communication with the
          user. These comprise accents and supporting colors that represent the
          state of the system.
        </p>
      </div>
      <div className="ColorSchemeContainer">
        <Grid spacing={1} container>
          <Grid spacing={1} item container justifyContent="space-between">
            <Grid item xl={4} lg={4} sm={6} xs={4}>
              <div className="tdiv111">
                Error-red
                <br />
                <div className="colorCode">#FF2121</div>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} sm={6} xs={4}>
              <div className="tdiv113">
                Success-green
                <br />
                <div className="colorCode">#1FC24E</div>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} sm={6} xs={4}>
              <div className="tdiv112">
                Warning-yellow
                <br />
                <div className="colorCode">#FEC600</div>
              </div>
            </Grid>
          </Grid>
          <Grid spacing={1} item container justifyContent="space-between">
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <div className="tdiv21">
                Aqua-deep
                <br />
                <div className="colorCode">#12877F</div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} sm={6} xs={12}>
              <div className="tdiv22">
                Snack-blue
                <br />
                <div className="colorCode">#145599</div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

// //////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////
// ////////////////       BACK FRONT TAB        /////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////
