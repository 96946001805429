import React from "react";
import { Button } from "@material-ui/core";
import { useState } from "react";
import "./../../sass/components/pageComponents.scss";
import clip from "../assets/Vector.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import forwardArrow from "./assets/arrow_forward.svg";
import environments from "../../config.js";

export function TitleClipIcon(props) {
  return (
    <div
      className={`titleClipIcon${
        props.first ? " header-mobile-top-offset" : ""
      }`}
    >
      <div className="titleClipIcon__title">{props.title}</div>
      <img src={clip} alt="clip Icon" />
    </div>
  );
}

export function CodeSnippet(props) {
  const [value] = useState(props.value);
  const [copied, setCopied] = useState(false);
  const [hidden, setHidden] = useState(true);

  return (
    <div>
      <div className="blackDiv">
        <div className="blackDivParaContainer">{props.value}</div>
        <CopyToClipboard
          text={value}
          value={copied}
          onCopy={() => {
            setCopied(true);
            setHidden(false);
            setTimeout(() => {
              setHidden(true);
            }, 1000);
          }}
        >
          {/* className={(hidden ? null : classes.outline)} */}
          <Button>
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9844 21V6.98438H6.01562V21H16.9844ZM16.9844 5.01562C17.5156 5.01562 17.9844 5.21875 18.3906 5.625C18.7969 6 19 6.45312 19 6.98438V21C19 21.5312 18.7969 22 18.3906 22.4062C17.9844 22.8125 17.5156 23.0156 16.9844 23.0156H6.01562C5.48438 23.0156 5.01562 22.8125 4.60938 22.4062C4.20312 22 4 21.5312 4 21V6.98438C4 6.45312 4.20312 6 4.60938 5.625C5.01562 5.21875 5.48438 5.01562 6.01562 5.01562H16.9844ZM13.9844 0.984375V3H1.98438V17.0156H0.015625V3C0.015625 2.46875 0.203125 2 0.578125 1.59375C0.984375 1.1875 1.45312 0.984375 1.98438 0.984375H13.9844Z"
                fill="white"
              />
            </svg>
          </Button>
        </CopyToClipboard>
      </div>

      <div className="hiddenCopied">
        {hidden ? null : (
          <span className="alignLeft" style={{ color: "#ED1164" }}>
            Copied To clipboard
          </span>
        )}
      </div>
    </div>
  );
}

export function StoryBookCard(props) {
  return (
    <a
      className="storyBookCardLink"
      href={`${environments.BASE_URL}/components-${props.comp}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="storyBookCard">
        <div className="storyBookCard__top">
          <div className="storyBookCard__top__header">
            <p>Storybook</p>
          </div>
          <div className="storyBookCard__top__arrow">
            <img src={forwardArrow} alt="forward arrow" />
          </div>
        </div>
        <div className="storyBookCard__desc">
          <p>{props.desc}</p>
        </div>
      </div>
    </a>
  );
}
