import React, { useEffect, useState } from "react";
import "./landingHeader.scss";
// import subzeroO from '../../../components/assets/SubzeroO.svg'
import subzeroLogo from "../../../components/assets/SubzeroLogoText.svg";
import versionChip from "../../../components/assets/version-chip.svg";
import SubzeroSidebarAnimation from "../../loadingAnimation/SubzeroSidebarAnimation";
import { NavLink } from "react-router-dom";

const LandingHeader = () => {
  const [show, setShow] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition >= scrollTop && currentPosition > 68) {
        setShow(false);
      } else {
        setShow(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop, show]);
  return (
    <div className={show ? "active" : "hidden"}>
      {/* <div className='headerContainerWrapper'> */}
      <div className="headerContainer">
        <NavLink className="headerContainer__logo__displayFlex" to="/">
          <SubzeroSidebarAnimation />
          <div className="headerContainer__logo">
            {/* <img className='headerContainer__logo__displayFlex__subzeroO ' src={subzeroO} alt='subzeroLogo Animation' /> */}
            <img src={subzeroLogo} alt="subzeroLogo" />
            <img
              className="headerContainer__logo__displayFlex__version "
              src={versionChip}
              alt="versionChip"
            />
          </div>
        </NavLink>

        <div className="headerContainer__rightDiv">
          <NavLink
            className="headerContainer__rightDiv__link"
            activeClassName="current"
            to="/foundations"
          >
            <p className="headerContainer__rightDiv__link__para">Foundations</p>
          </NavLink>

          <NavLink
            className="headerContainer__rightDiv__link "
            activeClassName="current"
            to="/components"
          >
            <p>Components</p>
          </NavLink>

          <NavLink
            className="headerContainer__rightDiv__link"
            activeClassName="current"
            to="/about"
          >
            <p>About</p>
          </NavLink>

          <NavLink
            className="headerContainer__rightDiv__link"
            activeClassName="current"
            to="/forDesigners"
          >
            <p>For Designers</p>
          </NavLink>

          <NavLink
            className="headerContainer__rightDiv__link"
            activeClassName="current"
            to="/forDevelopers"
          >
            <p>For Developers</p>
          </NavLink>
          {/* <NavLink className='headerContainer__rightDiv__link' activeClassName='current' to='/Releases'>
            <p>Releases</p>
          </NavLink> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default LandingHeader;
