import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addScroll } from "../../../hooks/enableInternalScroll";

function ColorsTable(props) {
  const containerRef = React.createRef();

  useEffect(() => {
    if (containerRef.current) {
      addScroll(containerRef.current);
    }
  }, []);
  return (
    <div className="colorsTable">
      <div className="title">{props.title}</div>
      <TableContainer ref={containerRef}>
        <Table className="table" aria-label="simple table" size="small">
          <TableHead className="headRow">
            <TableRow>
              <TableCell className="typography-column fontWeightNormal paddingLeft-12px">
                NAME
              </TableCell>
              <TableCell className="typography-column fontWeightNormal">
                VALUE
              </TableCell>
              <TableCell className="typography-column fontWeightNormal">
                TOKEN
              </TableCell>
              <TableCell className="typography-column fontWeightNormal">
                ROLE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="body">
            {props.colors.map((colorData) => {
              return (
                <TableRow className="tableRow" key={colorData.name}>
                  <TableCell className="typography-column paddingLeft-12px">
                    <div className="color">
                      <div
                        className="colorPreview"
                        style={{
                          background: colorData.value,
                          border:
                            colorData.value === "#FFFFFF"
                              ? "1px solid #F1F1F1"
                              : "",
                        }}
                      ></div>
                      <div className="colorName">{colorData.name}</div>
                    </div>
                  </TableCell>
                  <TableCell className="typography-column" align="left">
                    {colorData.value}
                  </TableCell>
                  <TableCell className="typography-column">
                    <button className="copy-token-btn">
                      <CopyToClipboard text={colorData.token}>
                        <p className="tokenComp">{colorData.token}</p>
                      </CopyToClipboard>
                    </button>
                  </TableCell>
                  <TableCell className="typography-column colorRoles">
                    {colorData.role.map((roleData) => {
                      return <div key={roleData}>{roleData}</div>;
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ColorsTable;
