const leftDrawerData = [
  {
    title: "Sections",
    subData: [
      { subTitle: "Foundations", link: "/foundations", key: "32" },
      { subTitle: "Components", link: "/components", key: "33" },
      { subTitle: "About", link: "/about", key: "29" },
      { subTitle: "For Designer", link: "/forDesigners", key: "30" },
      { subTitle: "For Developers", link: "/forDevelopers", key: "31" },
    ],
  },
  {
    title: "Foundations",
    subData: [
      { subTitle: "Design Principles", link: "/designPrinciples", key: "1" },
      { subTitle: "Layout", link: "/layout", key: "2" },
      { subTitle: "Typography", link: "/typography", key: "3" },
      { subTitle: "Colors", link: "/colors", key: "4" },
      { subTitle: "Spacing", link: "/spacing", key: "5" },
      { subTitle: "Elevation", link: "/elevation", key: "6" },
      // { subTitle: 'Icons', link: '/icons', key: '9' },
      // { subTitle: 'Badges', link: '/badges', key: '10' },
      // { subTitle: 'Illustrations', link: '/illustration', key: ' 6' },
      // { subTitle: 'Motion', link: '/motion', key: '7' },
    ],
  },
  {
    title: "Components",
    subData: [
      { subTitle: "Accordion", link: "/accordion", key: "7" },
      { subTitle: "App Bar", link: "/appbar", key: "8" },
      { subTitle: "Avatar", link: "/avatar", key: "9" },
      { subTitle: "Bottom Navigation", link: "/bottomnavigation", key: "10" },
      { subTitle: "Breadcrumb", link: "/breadcrumb", key: "11" },
      { subTitle: "Button", link: "/buttons", key: "12" },
      { subTitle: "Card", link: "/card", key: "13" },
      { subTitle: "Checkbox", link: "/checkbox", key: "14" },
      { subTitle: "Chip", link: "/chip", key: "15" },
      { subTitle: "CoachMark", link: "/coachmark", key: "16" },
      { subTitle: "Content Slider", link: "/contentslider", key: "17" },
      { subTitle: "Date picker", link: "/datepicker", key: "18" },
      { subTitle: "Digit input", link: "/digitInput", key: "19" },
      { subTitle: "Divider", link: "/divider", key: "20" },
      { subTitle: "Dropdown", link: "/dropdown", key: "21" },
      { subTitle: "File uploader", link: "/fileUploader", key: "22" },
      {
        subTitle: "Floating Action Button",
        link: "/floating-action-button",
        key: "23",
      },
      { subTitle: "Header", link: "/header", key: "24" },
      { subTitle: "Input Helper", link: "/inputHelper", key: "25" },
      { subTitle: "Link", link: "/links", key: "26" },
      { subTitle: "Menu", link: "/menu", key: "27" },
      { subTitle: "Onboarding", link: "/onboarding", key: "28" },
      { subTitle: "Progress Indicator", link: "/progressIndicator", key: "29" },
      { subTitle: "Progress Tracker", link: "/progressTracker", key: "30" },
      { subTitle: "Radio Button", link: "/radio", key: "31" },
      { subTitle: "Tabs", link: "/tabs", key: "32" },
      { subTitle: "Tag", link: "/tag", key: "33" },
      { subTitle: "Text Field", link: "/textField", key: "34" },
      { subTitle: "Toggle", link: "/toggle", key: "35" },
      { subTitle: "Yes-No Switch", link: "/yesNo", key: "36" },
    ],
  },
];

export default leftDrawerData;
