import React, { useState, useEffect } from "react";
import "./App.scss";
import MobileHeader from "./components/mobileHeader/MobileHeader";
import MainRouterBody from "./components/MainRouterBody";

import useWindowDimensions from "./hooks/useWindowDimensions";
import UncontrolledLottie from "./components/loadingAnimation/UncontrolledLottie";
import LandingHeader from "./components/commonComponents/landingHeader/LandingHeader";
import { CssBaseline } from "@material-ui/core";
export const GlobalState = React.createContext();

function App() {
  const [showLoader, setshowLoader] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const [reloadSideBar, setRelaodSideBar] = useState(false);
  const [showtTopBarNavAnim, setshowtTopBarNavAnim] = useState(false);
  const { width } = useWindowDimensions();
  const [renderSideBar, setrenderSideBar] = useState(true);
  useEffect(() => {
    setshowLoader(true);
    if (window.location.pathname === "/") {
      setTimeout(() => {
        setshowtTopBarNavAnim(true);
      }, 3500);
    } else {
      setshowtTopBarNavAnim(true);
    }
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/") {
      if (width > 1025)
        setTimeout(() => {
          setshowLoader(false);
        }, 6650);
      else {
        setTimeout(() => {
          setshowLoader(false);
        }, 6420);
      }
    } else {
      setshowLoader(false);
    }
  }, []);

  return (
    <div>
      {width > 1025 && showtTopBarNavAnim && (
        <LandingHeader setShowSidebar={setShowSideBar} />
      )}
      <div>
        {width < 1025 && showtTopBarNavAnim && (
          <MobileHeader
            renderSideBar={renderSideBar}
            setrenderSideBar={setrenderSideBar}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
            showMobileSideBar={showMobileSideBar}
            setShowMobileSideBar={setShowMobileSideBar}
          />
        )}
      </div>
      {showLoader ? (
        <div>
          <CssBaseline />
          <UncontrolledLottie />
        </div>
      ) : (
        <div>
          <CssBaseline />{" "}
          <GlobalState.Provider
            value={{
              showSideBar: { showSideBar, setShowSideBar },
              showMobileSideBar: { showMobileSideBar, setShowMobileSideBar },
              reloadSideBar: { reloadSideBar, setRelaodSideBar },
              renderSideBar: { renderSideBar, setrenderSideBar },
            }}
          >
            <div className="app">
              <div className="mainRouterBody">
                <MainRouterBody
                  renderSideBar={renderSideBar}
                  setrenderSideBar={setrenderSideBar}
                  showSideBar={showSideBar}
                  setShowSidebar={setShowSideBar}
                  showMobileSideBar={showMobileSideBar}
                  setShowMobileSideBar={setShowMobileSideBar}
                />
              </div>
            </div>
          </GlobalState.Provider>
        </div>
      )}
    </div>
  );
}

export default App;
