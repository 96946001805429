import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./FullWidthTabsStyle.scss";
import "../../sass/components/headingParaStyles.scss";

const AntTabs = withStyles({
  root: {
    borderBottom: "0px solid #F1F1F1",
    backgroundColor: "white",
    marginRight: "10px",
  },
  indicator: {
    borderBottom: "2px solid #f50057",
    borderRadius: "2px",
    // backgroundColor: "#f50057",
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    textTransform: "none",
    padding: "0px",
    borderBottom: "1px solid #F1F1F1",
    // minWidth: '192px',
    color: "#9D9D9D",
    fontSize: "14px",
    height: "38px",
    fontFamily: "Lato",
    paddingBottom: "8px",
    paddingTop: "8px",
    "&:hover": {
      // color: '#40a9ff',
      opacity: 1,
    },
    "&$selected": {
      color: "#ED1164",
      // borderBottom: '2px solid #ED1164',
    },
    "&:focus": {
      // color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div">
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

// const drawerWidth = 248
// const useStyles = makeStyles((theme) => ({
//   root: {
//     borderColor: 'white',
//     width: '100%',
//     fontFamily: 'Lato',
//     margin: '0%',
//     padding: '0%'
//   },

//   tabscustom: {
//     border: 'none',
//     boxShadow: 'none',
//     borderBottom: '1px solid #E2E2E2',
//     width: '100%'
//   },

//   tabsview: {
//     textAlign: 'left',
//     marginLeft: '164px',
//     width: `calc(100% - ${drawerWidth}px)`,
//     padding: '0px'
//   },

//   headerName: {
//     paddingTop: '64px',
//     paddingBottom: '40px',
//     marginLeft: '184px',
//     textAlign: 'left',
//     fontSize: '44px',
//     fontFamily: 'Lato'
//   },

//   para: {
//     width: '576px',
//     paddingBottom: '34px',
//     fontFamily: 'Lato'
//   },

//   tabsection: {
//     width: '100%'

//   }
// }))

export default function FullWidthTabs(props) {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    props.setter(newValue);
  };
  const handleChangeIndex = (index) => {
    if (index >= 2) {
      props.setter(2);
    } else {
      props.setter(index);
    }
  };
  return (
    <div className="fullwidth">
      <h1 className="fullwidth__pageTitle" data-testid="fullwidth__pageTitle">
        {props.pageTitle}
      </h1>

      <AppBar
        className="fullwidth__appBar"
        position="static"
        color="transparent"
      >
        <div>
          <AntTabs
            variant={window.screen.width <= 768 ? "fullWidth" : "standard"}
            value={props.index}
            onChange={handleChange}
            aria-label="ant example"
            className="fullwidth__appBar__antTabs"
          >
            {props.overview ? (
              <AntTab
                className="fullwidth__appBar__antTabs__antTab"
                label="Overview"
                {...a11yProps(0)}
              />
            ) : null}

            {props.style ? (
              <AntTab
                className="fullwidth__appBar__antTabs__antTab"
                label="Style"
                {...a11yProps(1)}
              />
            ) : null}

            {props.code ? (
              <AntTab
                className="fullwidth__appBar__antTabs__antTab"
                label="Code"
                {...a11yProps(2)}
              />
            ) : null}
          </AntTabs>
        </div>
      </AppBar>
      <div className="fullwidth__swipeableViews">
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={props.index}
          onChangeIndex={handleChangeIndex}
          className="fullwidth__swipeableViews__tabsview"
        >
          <TabPanel
            classes={{
              root: "fullwidth__swipeableViews__tabsview__tabPanel",
            }}
            value={props.index}
            index={0}
            dir={theme.direction}
          >
            <div className="padding64px" />
            {props.overview}
          </TabPanel>
          <TabPanel value={props.index} index={1} dir={theme.direction}>
            {props.style}
          </TabPanel>
          <TabPanel value={props.index} index={2} dir={theme.direction}>
            {props.code}
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}
