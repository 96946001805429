import React from "react";
import Lottie from "lottie-web-react";
// import animationData from '../../ANIMATIONS/Accordion/Accordion.json'
const renderer = "svg";
const rendererSettings = {
  preserveAspectRatio: "xMidYMid meet",
};

function PageIllustrationAnimation({
  isHovered = false,
  animationData,
  setisHovered,
}) {
  return (
    <Lottie
      style={{
        overflow: "hidden !important",
      }}
      // width='100%'
      options={{
        renderer: renderer,
        animationData: animationData,
        rendererSettings: rendererSettings,
      }}
      playingState={isHovered ? "play" : "stop"}
      eventListeners={[
        {
          eventName: "complete",
          callback: () => {
            setisHovered(false);
          },
        },
      ]}
    />
  );
}
export default PageIllustrationAnimation;
