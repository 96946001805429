import React, { useState } from "react";
import Lottie from "lottie-web-react";
import animationData from "../../ANIMATIONS/lazyLoading/Loader 2slower.json";
// import animationData from "../../ANIMATIONS/lazyLoading/Loader_02.json";
const renderer = "svg";
const rendererSettings = {
  preserveAspectRatio: "xMidYMid meet",
};

function LazyLoadingAnim() {
  // const [playingState, setPlayingState] = useState('stop')
  const [autoplay] = useState(true);
  const [loop] = useState(true);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Lottie
        style={{
          overflow: "hidden !important",
        }}
        options={{
          renderer: renderer,
          loop: loop,
          autoplay: autoplay,
          // path: path,
          animationData: animationData,
          rendererSettings: rendererSettings,
        }}
        playingState="play"
      />
    </div>
  );
}
export default LazyLoadingAnim;

// import React, { useState } from 'react';
// import Lottie from 'lottie-web-react'
// import animationData from '../../Interaction Icon 2/Text/Text.json'

// function IllustrationExample() {
//   const [playingState, setPlayingState] = useState('stop')
//   const [popUp, setpopUp] = useState(false)
//   const [autoplay, setAutoplay] = useState(false)
//   const [loop, setLoop] = useState(false)
//   const renderer = 'svg'
//   const rendererSettings = {
//     preserveAspectRatio: 'xMinYMin slice'
//   }
//   return (
//     <div
//       style={{ width: '33px', height: '33px' }}
//       onMouseEnter={() => { setpopUp(true); setPlayingState('play') }}
//       onMouseLeave={() => setpopUp(false)}
//     // onClick={() => }
//     >
//       <Lottie
//         srtyle={{ width: '33px', height: '33px' }}
//         options={{
//           renderer: renderer,
//           loop: loop,
//           autoplay: autoplay,
//           animationData: animationData,
//           rendererSettings: rendererSettings
//         }}
//         playingState={playingState}
//       />
//     </div>

//   )
// }

// export default IllustrationExample

// // import React from 'react';
// // import lottie from 'lottie-web';
// // import animationData from '../../Interaction Icon 2/Text/Text.json'

// // function IllustrationExample() {
// //   const animateSubzero = () => {
// //     lottie.loadAnimation({
// //       container: document.querySelector("#lottie"),
// //       animationData: animationData,
// //       // onComplete: animateLoader,
// //       renderer: "svg", // "canvas", "html"
// //       loop: true, // boolean
// //       autoplay: true // boolean
// //     })
// //   }

// //   React.useEffect(() => {
// //     animateSubzero()
// //   }, []);
// //   return (
// //     <div
// //       style={{ width: '100%', height: '100%', border: '1px solid red' }}
// //       id="lottie"
// //     />
// //   )
// // }

// // export default IllustrationExample
