export function addScroll(containerNode) {
  //   console.log("Scroll added");
  containerNode.addEventListener("touchstart", isolateTouch, {
    passive: true,
  });
  containerNode.addEventListener("touchmove", isolateTouch, {
    passive: true,
  });
  containerNode.addEventListener("touchend", isolateTouch, { passive: true });
}

export function removeScroll(containerNode) {
  containerNode.removeEventListener("touchstart", isolateTouch, {
    passive: true,
  });
  containerNode.removeEventListener("touchmove", isolateTouch, {
    passive: true,
  });
  containerNode.removeEventListener("touchend", isolateTouch, {
    passive: true,
  });
}

function isolateTouch(e) {
  e.stopPropagation();
}
