import React, { useEffect, useRef, Suspense, lazy } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// import Typography from '@material-ui/core/Typography'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ColorsPage from "./pages/colorPage/ColorsPage";
import leftDrawerData from "./leftDrawerData";
// React Router Import
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import "../App.scss";

// import subzeroO from './assets/SubzeroO.svg'
import subzeroLogo from "./assets/SubzeroLogoText.svg";
import versionChip from "./assets/version-chip.svg";

// import TypographyPage from './pages/typographyPage/TypographyPage'
// import SpacingPage from './pages/spacing/SpacingPage'
// import ButtonPage from './pages/buttonPage/ButtonPage'
// import RadioPage from './pages/radioPage/RadioPage'
// import CheckboxPage from './pages/checkboxPage/CheckboxPage'
// import LinkPage from './pages/linkPage/LinkPage'
// import TogglePage from './pages/togglePage/TogglePage'
// import MenuPage from './pages/menuPage/MenuPage'
// import ChipPage from './pages/chip/ChipPage'
// import TestingPage from './pages/testingGrid/TestingPage'
// import TextFieldPage from './pages/textFieldPage/TextFieldPage'
// import HeaderPage from './pages/headerPage/HeaderPage'
// import BreadcrumbPage from './pages/breadcrumbPage/BreadcrumbPage'
// import DigitalInputPage from './pages/digitalInputPage/DigitalInputPage'
// import DividerPage from './pages/divider/DividerPage'
// import YesNoPage from './pages/yesNoSwitch/YesNoPage'

// import NewPage from './pages/newPage/NewPage'
// import ProgressIndicatorPage from './pages/progressIndicator/ProgressIndicatorPage'
// import ProgressTrackerPage from './pages/progressTracker/ProgressTrackerPage'
// import InputHelperPage from './pages/inputHelper/InputHelperPage'
// import AccordionPage from './pages/accordion/AccordionPage'
// import DatePickerPage from './pages/datePicker/DatePickerPage'
// import CardPage from './pages/card/CardPage'
// import DropdownPage from './pages/dropdown/DropdownPage'
// import FileUploaderPage from './pages/fileUploader/FileUploaderPage'
// import LayoutPage from './pages/layout/LayoutPage'
// import LandingPageMain from './pages/LandingPage/LandingPageMain'
// import FoundationsGettingStarted from './pages/LandingPage/gettingStarted/FoundationsGettingStarted'
// import ComponentsGettingStarted from './pages/LandingPage/gettingStarted/ComponentsGettingStarted'

// import DesignerGettingStarted from './pages/LandingPage/gettingStarted/DesignerGettingStarted'
// import DeveloperGettingStarted from './pages/LandingPage/gettingStarted/DeveloperGettingStarted'
// import SplashPage from './pages/LandingPage/SplashPage'
// import DesignPrinciplesPage from './pages/designPrinciples/DesignPrinciplesPage'
// import IllustrationPage from './pages/IllustrationPage/IllustrationPage'
// import AboutPage from './pages/aboutPage/AboutPage'
// import ReleasesPage from './pages/releasesPage/ReleasesPage'

import ScrollToTop from "./commonComponents/ScrollToTop";
// import useWindowDimensions from '../hooks/useWindowDimensions'
import Media from "react-media";
import { Divider } from "@material-ui/core";
// import MotionPage from "./pages/MotionPage/MotionPage";
import { GlobalState } from "../App";

import "./PermanentDrawerLeftStyle.scss"; // style sheet
import SubzeroSidebarAnimation from "./loadingAnimation/SubzeroSidebarAnimation";

import LazyLoadingAnim from "./loadingAnimation/LazyLoadingAnim";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Footer from "./commonComponents/Footer/Footer";
// import useReactPath from '../hooks/useReactPath'

// lazyLoadingImport
const TypographyPage = lazy(() =>
  import("./pages/typographyPage/TypographyPage")
);
const ElevationPage = lazy(() => import("./pages/elevation/ElevationPage"));
const SpacingPage = lazy(() => import("./pages/spacing/SpacingPage"));
const ButtonPage = lazy(() => import("./pages/buttonPage/ButtonPage"));
const RadioPage = lazy(() => import("./pages/radioPage/RadioPage"));
const CheckboxPage = lazy(() => import("./pages/checkboxPage/CheckboxPage"));
const LinkPage = lazy(() => import("./pages/linkPage/LinkPage"));
const TogglePage = lazy(() => import("./pages/togglePage/TogglePage"));
const MenuPage = lazy(() => import("./pages/menuPage/MenuPage"));
const ChipPage = lazy(() => import("./pages/chip/ChipPage"));
const TextFieldPage = lazy(() => import("./pages/textFieldPage/TextFieldPage"));
const HeaderPage = lazy(() => import("./pages/headerPage/HeaderPage"));
const BreadcrumbPage = lazy(() =>
  import("./pages/breadcrumbPage/BreadcrumbPage")
);
const DigitalInputPage = lazy(() =>
  import("./pages/digitalInputPage/DigitalInputPage")
);
const DividerPage = lazy(() => import("./pages/divider/DividerPage"));
const YesNoPage = lazy(() => import("./pages/yesNoSwitch/YesNoPage"));

const NewPage = lazy(() => import("./pages/newPage/NewPage"));
const ProgressIndicatorPage = lazy(() =>
  import("./pages/progressIndicator/ProgressIndicatorPage")
);
const ProgressTrackerPage = lazy(() =>
  import("./pages/progressTracker/ProgressTrackerPage")
);
const InputHelperPage = lazy(() =>
  import("./pages/inputHelper/InputHelperPage")
);
const AccordionPage = lazy(() => import("./pages/accordion/AccordionPage"));
const DatePickerPage = lazy(() => import("./pages/datePicker/DatePickerPage"));
const CardPage = lazy(() => import("./pages/card/CardPage"));
const DropdownPage = lazy(() => import("./pages/dropdown/DropdownPage"));
const FileUploaderPage = lazy(() =>
  import("./pages/fileUploader/FileUploaderPage")
);
const LayoutPage = lazy(() => import("./pages/layout/LayoutPage"));
const LandingPageMain = lazy(() =>
  import("./pages/LandingPage/LandingPageMain")
);

const FabPage = lazy(() => import("./pages/FloatingActionButton/FabPage"));
const TabsPage = lazy(() => import("./pages/tabs/TabsPage"));
const FoundationsGettingStarted = lazy(() =>
  import("./pages/LandingPage/gettingStarted/FoundationsGettingStarted")
);
const ComponentsGettingStarted = lazy(() =>
  import("./pages/LandingPage/gettingStarted/ComponentsGettingStarted")
);

const DesignerGettingStarted = lazy(() =>
  import("./pages/LandingPage/gettingStarted/DesignerGettingStarted")
);
const DeveloperGettingStarted = lazy(() =>
  import("./pages/LandingPage/gettingStarted/DeveloperGettingStarted")
);
const SplashPage = lazy(() => import("./pages/LandingPage/SplashPage"));
const DesignPrinciplesPage = lazy(() =>
  import("./pages/designPrinciples/DesignPrinciplesPage")
);
// const IllustrationPage = lazy(() =>
//   import("./pages/IllustrationPage/IllustrationPage")
// );
const AboutPage = lazy(() =>
  import("./pages/LandingPage/gettingStarted/About")
);
const ReleasesPage = lazy(() => import("./pages/releasesPage/ReleasesPage"));
const TagPage = lazy(() => import("./pages/tag/TagPage"));
const BottomNavigationPage = lazy(() =>
  import("./pages/bottomNavigation/BottomNavigationPage")
);
const AppBarPage = lazy(() => import("./pages/appBar/AppBarPage"));
const AvatarPage = lazy(() => import("./pages/avatar/AvatarPage"));
const ContentSliderPage = lazy(() =>
  import("./pages/contentSlider/ContentSliderPage")
);
const CoachMarkPage = lazy(() => import("./pages/coachMark/CoachMarkPage"));

const OnBoardingPage = lazy(() => import("./pages/onBoarding/OnBoardingPage"));

export default function MainRouterBody(props) {
  const { width } = useWindowDimensions();
  const classes = useStyles(); // defined below the scope of this function
  const history = useHistory();
  useEffect(() => {
    if (
      history.location.pathname === "/" ||
      history.location.pathname === "/forDesigners" ||
      history.location.pathname === "/forDevelopers" ||
      history.location.pathname === "/components" ||
      history.location.pathname === "/foundations" ||
      history.location.pathname === "/about"
    ) {
      props.setrenderSideBar(false);
    } else {
      props.setrenderSideBar(true);
    }
    if (width < 1025) {
      props.setrenderSideBar(true);
    }
  }, [props, history.location.pathname, width]);

  return (
    <div className={classes.root}>
      <ScrollToTop />
      {props.renderSideBar && (
        <RenderListItems
          showSideBar={props.setrenderSideBar}
          showMobileSideBar={props.showMobileSideBar}
          setShowMobileSideBar={props.setShowMobileSideBar}
        />
      )}

      {/* <LandingHeader /> */}
      <main className={classes.content}>
        {/* <div className={props.showMobileSideBar ? "disabledMainBody" : ""}> */}
        <div>
          <Suspense fallback={<LazyLoadingAnim />}>
            <Switch>
              {/* <Route exact path='/' component={homePage} /> */}
              <Route exact path="/" component={LandingPageMain} />
              <Route exact path="/colors" component={ColorsPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/spacing" component={SpacingPage} />
              <Route exact path="/elevation" component={ElevationPage} />
              <Route exact path="/breadcrumb" component={BreadcrumbPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/radio" component={RadioPage} />
              <Route exact path="/checkbox" component={CheckboxPage} />
              <Route exact path="/chip" component={ChipPage} />
              <Route exact path="/header" component={HeaderPage} />
              <Route exact path="/links" component={LinkPage} />
              <Route exact path="/digitinput" component={DigitalInputPage} />
              <Route exact path="/divider" component={DividerPage} />
              <Route exact path="/textField" component={TextFieldPage} />
              <Route exact path="/toggle" component={TogglePage} />
              <Route exact path="/yesNo" component={YesNoPage} />
              <Route exact path="/menu" component={MenuPage} />
              <Route exact path="/newPage" component={NewPage} />
              <Route exact path="/tabs" component={TabsPage} />
              <Route exact path="/floating-action-button" component={FabPage} />
              <Route
                exact
                path="/progressIndicator"
                component={ProgressIndicatorPage}
              />
              <Route
                exact
                path="/progressTracker"
                component={ProgressTrackerPage}
              />
              <Route exact path="/inputHelper" component={InputHelperPage} />
              <Route exact path="/accordion" component={AccordionPage} />
              <Route exact path="/datePicker" component={DatePickerPage} />
              <Route exact path="/card" component={CardPage} />
              <Route exact path="/dropdown" component={DropdownPage} />
              <Route exact path="/fileUploader" component={FileUploaderPage} />
              <Route exact path="/layout" component={LayoutPage} />
              <Route
                exact
                path="/foundations"
                component={FoundationsGettingStarted}
              />
              <Route
                exact
                path="/components"
                component={ComponentsGettingStarted}
              />
              <Route exact path="/about" component={AboutPage} />
              <Route
                exact
                path="/forDesigners"
                component={DesignerGettingStarted}
              />
              <Route
                exact
                path="/forDevelopers"
                component={DeveloperGettingStarted}
              />
              <Route
                exact
                path="/releases"
                component={() => (
                  <ReleasesPage setShowSidebar={props.setShowSidebar} />
                )}
              />
              <Route exact path="/splashScreen" component={SplashPage} />
              <Route
                exact
                path="/designPrinciples"
                component={DesignPrinciplesPage}
              />
              {/* <Route exact path="/illustration" component={IllustrationPage} />
              <Route exact path="/motion" component={MotionPage} /> */}
              <Route exact path="/tag" component={TagPage} />
              <Route
                exact
                path="/bottomnavigation"
                component={BottomNavigationPage}
              />
              <Route exact path="/appbar" component={AppBarPage} />
              <Route exact path="/avatar" component={AvatarPage} />
              <Route
                exact
                path="/contentslider"
                component={ContentSliderPage}
              />
              <Route exact path="/onboarding" component={OnBoardingPage} />
              <Route exact path="/coachmark" component={CoachMarkPage} />
            </Switch>
          </Suspense>
          <footer>
            <Footer />
          </footer>
        </div>
      </main>
    </div>
    // </Router>
  );
}

//  Making Style for Drawers
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    margin: "0%",
    padding: "0%",
  },
  drawer: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    width: drawerWidth,
    height: "100%",
  },
  drawerPaper: {
    width: drawerWidth,
    "&::-webkit-scrollbar": {
      display: "none",
    },

    ["@media (max-width:1024px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
    },
  },
  // necessary for content to be below app bar
  drawerList: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  content: {
    textAlign: "center",
    backgroundColor: "white",
    flexGrow: 1,
  },
  subzeroLogoItem: {
    padding: "0px",
    paddingLeft: "10px",
  },
  subzeroLogoItemIcon: {
    height: "68px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingTop: '10px'
  },
  versionChip: {
    paddingLeft: "8px",
  },
  subzeroText: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "700px",
  },
  subDataTabHeader: {
    // styleName: Heading/subheading-0
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    //styleName: UI / Heading / heading-6
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
    letterSpacing: "0.3px",
    textAlign: "left",
    height: "38px",
    padding: "0px",
    margin: "0px",

    // ["@media (max-width:720px)"]: {
    //   // eslint-disable-line no-useless-computed-key
    //   justifyContent: "right",
    // },
  },

  subDataTab: {
    // styleName: Body/body-2
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    height: "36px",
    paddingLeft: "16px",
    margin: "0px",

    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "200px",
    lineHeight: "20px",
    letterSpacing: "0.25999999046325684px",
    padding: "0px",
  },

  muList: {
    padding: "0%",
    "&: hover": {
      background: "none !important",
    },
    // margin: '0%'
  },
  subsectionList: {
    paddingBottom: "16px",
  },
  drawerActiveLink: {
    backgroundColor: "red",
  },
}));

const drawerWidth = 248;

function RenderListItems(props) {
  const classes = useStyles();
  const activeListRef = useRef(null);

  const globalState = React.useContext(GlobalState);
  const { reloadSideBar } = globalState;

  // const [windowlocate, setWindowlocate] = React.useState(window.location.pathname)
  // console.log(props, 'props in permant')
  // const history = useHistory()
  useEffect(() => {
    // setWindowlocate(window.location.pathname)
    reloadSideBar.setRelaodSideBar();
    activeListRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [reloadSideBar]);

  return (
    <Media query="(min-width:1025px)">
      {(matches) => {
        return matches ? (
          <Drawer
            className={classes.drawer}
            variant={props.showSideBar ? "permanent" : "temporary"}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <ListItem
              classes={{
                root: classes.subzeroLogoItem,
              }}
              button
            >
              <ListItemIcon
                classes={
                  {
                    // root: classes.subzeroLogoItemIcon
                  }
                }
              >
                <NavLink to="/">
                  <div className={classes.subzeroLogoItemIcon}>
                    <SubzeroSidebarAnimation />
                    {/* <img className='headerContainer__logo__displayFlex__subzeroO ' src={subzeroO} alt='subzeroLogo Animation' /> */}
                    <img src={subzeroLogo} alt="subzeroLogo" />
                    <img
                      className="headerContainer__logo__displayFlex__version "
                      src={versionChip}
                      alt="versionChip"
                    />
                  </div>
                </NavLink>
              </ListItemIcon>
            </ListItem>
            <Divider />
            <div className={classes.toolbar} />
            {/* <List disablePadding className={classes.muList}> */}
            <List disablePadding className={classes.drawerList}>
              {leftDrawerData?.map((content, id) => (
                <div key={id}>
                  <ListItem
                    button
                    classes={{
                      root: "muList",
                    }}
                  >
                    <ListItemText
                      disableTypography
                      variant="contained"
                      className="sideBar-right"
                      classes={{
                        root: classes.subDataTabHeader,
                      }}
                      // className={classes.subDataTabHeader}
                      primary={content.title}
                    />
                  </ListItem>
                  <List disablePadding className={classes.subsectionList}>
                    {content.subData.map((subContent) => (
                      <div key={subContent.key}>
                        <NavLink
                          ref={
                            window.location.pathname === subContent.link
                              ? activeListRef
                              : null
                          }
                          className="subDataTab"
                          activeClassName="drawerActiveLink"
                          key={subContent.key}
                          to={subContent.link}
                        >
                          <div>{subContent.subTitle}</div>
                        </NavLink>
                      </div>
                    ))}
                  </List>
                </div>
              ))}
            </List>
          </Drawer>
        ) : (
          <Drawer
            // className={classes.drawer}
            // variant='permanent'
            variant={props.showMobileSideBar ? "permanent" : "temporary"}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className={classes.toolbar} />
            {/* <List disablePadding className={classes.drawerList}> */}
            <List disablePadding className="drawerList">
              {leftDrawerData?.map((content, id) => (
                <div key={id}>
                  <ListItem
                    button
                    classes={{
                      root: "muList",
                    }}
                  >
                    <ListItemText
                      disableTypography
                      variant="contained"
                      classes={{
                        root: classes.subDataTabHeader,
                      }}
                      // className={classes.subDataTabHeader}
                      primary={content.title}
                    />
                  </ListItem>
                  <List disablePadding className={classes.subsectionList}>
                    {content.subData.map((subContent) => (
                      <NavLink
                        ref={
                          window.location.pathname === subContent.link
                            ? activeListRef
                            : null
                        }
                        className="subDataTab"
                        activeClassName="drawerActiveLink"
                        key={subContent.key}
                        to={subContent.link}
                        onClick={() => props.setShowMobileSideBar(false)}
                      >
                        <div>{subContent.subTitle}</div>
                      </NavLink>
                    ))}
                  </List>
                </div>
              ))}
            </List>
            <Divider />
          </Drawer>
        );
      }}
    </Media>
  );
}
