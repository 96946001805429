import React from "react";
import subzeroO from "../../components/assets/SubzeroO.svg";
import subzerologo from "../../components/assets/SubzeroLogoText.svg";
import versionChip from "../../components/assets/version-chip.svg";
// import mobMenuIcon from "../../components/assets/menuIconMob.svg";
import "./mobileHeader.scss";
import { NavLink } from "react-router-dom";

function MobileHeader(props) {
  const clickHandle = () => {
    props.setShowMobileSideBar(!props.showMobileSideBar);
    props.setrenderSideBar(!props.showMobileSideBar);
  };
  return (
    <div className="mobileHeader">
      <div className="mobileHeader__menu">
        <NavLink className="headerContainer__logo__displayFlex" to="/">
          <img
            className="headerContainer__logo__displayFlex__subzeroO "
            src={subzeroO}
            alt="subzeroLogo Animation"
          />
          <img
            className="mobileHeader__menu__subzeroText"
            src={subzerologo}
            alt="subzeroLogo"
          />
          <img width="30px" height="20px" src={versionChip} alt="versionChip" />
        </NavLink>
      </div>
      {/* <div className='mobileHeader_subzeroLogo'>
        <img src={subzerologo} alt='subzeroLogo' />
      </div> */}
      <div
        onClick={clickHandle}
        className={`hamburger${props.showMobileSideBar ? " active" : ""} `}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </div>
  );
}

export default MobileHeader;
