import React from "react";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./BackFrontTab.scss";

import { GlobalState } from "../../App";

function BackFrontTab(props) {
  const globalState = React.useContext(GlobalState);
  const { reloadSideBar } = globalState;

  const backClickHandle = () => {
    props.setter(props.index - 1);
    reloadSideBar.setRelaodSideBar(true);
    window.scroll(0, 0);
  };
  const frontClickHandle = () => {
    props.setter(props.index + 1);
    reloadSideBar.setRelaodSideBar(true);
    window.scroll(0, 0);
  };
  return (
    <div>
      <br />
      <br />
      <br />
      <Divider
        classes={{
          root: "dividerBackgroundColor",
        }}
      />
      <br />

      <div className="backFrontTab">
        {props.previousTab ? (
          <Link
            className="backTab"
            to={props.linkPrev ? props.linkPrev : "#"}
            onClick={backClickHandle}
          >
            <div className="tab1">
              <p>PREVIOUS</p>
            </div>
            <div className="tab2">
              <h2>{props.previousTab}</h2>
            </div>
          </Link>
        ) : null}

        {props.nextTab ? (
          <Link
            className="frontTab"
            to={props.linkNext ? props.linkNext : "#"}
            onClick={frontClickHandle}
          >
            <div className="tab1 ">
              <p>NEXT</p>
            </div>
            <div className="tab2">
              <h2>{props.nextTab}</h2>
            </div>
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default BackFrontTab;
