import React, { useState } from "react";
import Lottie from "lottie-web-react";
import animationData from "./assets/solidSlideU[pAnim.json";
// import animationDataMobile from "./assets/splashLoadingMobile.json";
import animationDataMobile from "./assets/logo animation_11.json";
import useWindowDimensions from "../../hooks/useWindowDimensions";
function UncontrolledLottie() {
  const [autoplay] = useState(true);
  const [loop] = useState(false);
  // const { width } = useWindowDimensions()
  const { width } = useWindowDimensions();

  const rendererSettings = {
    preserveAspectRatio: "xMinYMin slice",
  };
  return (
    <Lottie
      options={{
        renderer: "svg",
        loop: loop,
        autoplay: autoplay,
        animationData: width > 1025 ? animationData : animationDataMobile,
        rendererSettings: rendererSettings,
      }}
      playingState="play"
    />
  );
}
export default UncontrolledLottie;
