import React, { useState } from "react";
import FullWidthTabs from "../../commonComponents/FullWidthTabs";
import ColorCode from "./ColorCode";
import ColorsOverview from "./ColorsOverview";
import ColorStyle from "./ColorStyle";

function ColorsPage() {
  const [index, setIndex] = useState(0);
  return (
    <div className="ColorsPage">
      <FullWidthTabs
        setter={setIndex}
        index={index}
        pageTitle="Colors"
        overview={<ColorsOverview setter={setIndex} index={index} />}
        style={<ColorStyle setter={setIndex} index={index} />}
        code={<ColorCode setter={setIndex} index={index} />}
      />
    </div>
  );
}

export default ColorsPage;
