import React from "react";
// import commingSoonImg from "../../commonComponents/assets/comp-illustrations.png";
import BackFrontTab from "../../commonComponents/BackFrontTab";
import { TitleClipIcon } from "../../commonComponents/pageComponents";
import ColorsTable from "./colorsTable";

function ColorStyle(props) {
  const primaryColors = [
    {
      name: "Burgundy-100",
      value: "#97144D",
      token: "$burgundy-100",
      role: ["Brand color", "Headers", "Primary button background"],
    },
    {
      name: "White",
      value: "#FFFFFF",
      token: "$white",
      role: ["Background color", "Neutral backgrounds"],
    },
    {
      name: "Charcoal",
      value: "#282828",
      token: "$charcoal",
      role: ["Headings", "Text color", "Icon color"],
    },
  ];

  const secondaryColors = [
    {
      name: "Pink-100",
      value: "#ED1164",
      token: "$pink-100",
      role: ["Primary accent color"],
    },
    {
      name: "Pink-80",
      value: "#F14687",
      token: "$pink-80",
      role: ["Primary accent color tint"],
    },
    {
      name: "Pink-60",
      value: "#F9B0CC",
      token: "$pink-60",
      role: ["Primary accent color tint"],
    },
    {
      name: "Pink-40",
      value: "#F9B0CC",
      token: "$pink-40",
      role: ["Primary accent color tint"],
    },
    {
      name: "Pink-20",
      value: "#FDE5EE",
      token: "$pink-20",
      role: ["Primary accent color tint"],
    },
    {
      name: "Grey-100",
      value: "#404040",
      token: "$grey-100",
      role: ["Text color", "Neutral grey shadet"],
    },
    {
      name: "Grey-90",
      value: "#575757",
      token: "$grey-90",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-80",
      value: "#6E6E6E",
      token: "$grey-80",
      role: ["Secondary text color", "Neutral grey shade"],
    },
    {
      name: "Grey-70",
      value: "#858585",
      token: "$grey-70",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-60",
      value: "#9D9D9D",
      token: "$grey-60",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-50",
      value: "#B4B4B4",
      token: "$grey-50",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-40",
      value: "#CBCBCB",
      token: "$grey-40",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-30",
      value: "#E2E2E2",
      token: "$grey-30",
      role: ["Neutral grey shade"],
    },
    {
      name: "Grey-20",
      value: "#F1F1F1",
      token: "$grey-20",
      role: ["Container borders", "Divider", "Neutral grey shade"],
    },
    {
      name: "Grey-10",
      value: "#F9F9F9",
      token: "$grey-10",
      role: ["Secondary backgrounds", "Neutral grey shade"],
    },
  ];

  const tertiaryColors = [
    {
      name: "Error red",
      value: "#FF2121",
      token: "$red",
      role: ["Brand color", "Headers", "Primary button background"],
    },
    {
      name: "Success green",
      value: "#1FC24E",
      token: "$green",
      role: ["Background color", "Neutral backgrounds"],
    },
    {
      name: "Warning yellow",
      value: "#FEC600",
      token: "$yellow",
      role: ["Secondary warning/pending indicator"],
    },
    {
      name: "Warning orange",
      value: "#F17F22",
      token: "$orange",
      role: ["Warning/pending indicator"],
    },
    {
      name: "Snack blue",
      value: "#145599",
      token: "$snackblue",
      role: ["Text color in snackbars"],
    },
    {
      name: "Purple",
      value: "#9911ED",
      token: "$purple",
      role: ["Visited links"],
    },
    {
      name: "Aqua haze 1",
      value: "#F2F7F6",
      token: "$aqua-haze-1",
      role: ["Text field background"],
    },
    {
      name: "Aqua haze 2",
      value: "#E6F8F4",
      token: "$aqua-haze-2",
      role: ["Text field hover background"],
    },
    {
      name: "Aqua deep-100",
      value: "#12877F",
      token: "$aqua-deep-100",
      role: ["Text field active activation indicator"],
    },
    {
      name: "Aqua deep-80",
      value: "#429F99",
      token: "$aqua-deep-80",
      role: ["Aqua deep tint"],
    },
    {
      name: "Aqua deep-60",
      value: "#71B7B2",
      token: "$aqua-deep-60",
      role: ["Text field default activation indicator"],
    },
    {
      name: "Aqua deep-40",
      value: "#71B7B2",
      token: "$aqua-deep-40",
      role: ["Aqua deep tint"],
    },
    {
      name: "Aqua deep-20",
      value: "#D0E7E5",
      token: "$aqua-deep-20",
      role: ["Aqua deep tint"],
    },
    {
      name: "Aqua deep-10",
      value: "#E7F3F2",
      token: "$aqua-deep-10",
      role: ["Aqua deep tint"],
    },
  ];

  return (
    <div>
      <TitleClipIcon title="Core color tokens" />
      <ColorsTable title="Primary colors" colors={primaryColors} />
      <ColorsTable title="Secondary colors" colors={secondaryColors} />
      <ColorsTable title="Tertiary colors" colors={tertiaryColors} />
      <BackFrontTab
        previousTab="Colors: Overview"
        nextTab="Colors: Code"
        setter={props.setter}
        index={props.index}
      />
    </div>
  );
}

export default ColorStyle;
