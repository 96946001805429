const environments = {
  development: {
    BASE_URL: "https://storybook.dev.axisb.com/?path=/story",
  },
  staging: {
    BASE_URL: "https://storybook.staging.axisb.com/?path=/story",
  },
  production: {
    BASE_URL: "https://storybook.axisbank.com/?path=/story",
  },
};
export default environments[process.env.REACT_APP_ENV] ||
  environments.production;
